import { Store } from "@reduxjs/toolkit";
import { SDK } from "@sdk";
import { iWidget } from "@sdk/services/models";
import { iStore } from "store/store.model";
import {
  setActivatedCheckLists,
  setActivatedMessageCampaigns,
  setActivatedTours,
  setNewsfeedTypes,
  setSession,
  setTicketTypes,
  setWidget,
} from "./session.actions";
import { selectWidget } from "./session.selectors";

export const loadWidget = (widgetId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getWidgetConfigurations(widgetId).then((data) => {
    store.dispatch(setWidget(data as iWidget));
  });
};

export const loadSession = (store: Store<iStore>, forceReload?: boolean) => {
  return SDK.getSessionData().then((data) => {
    store.dispatch(setSession(data));
  });
};

export const loadTicketTypes = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getAllTicketTypes().then((data) => {
    store.dispatch(setTicketTypes(data));
  });
};

export const loadActivatedMessageCampaigns = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getActiveMessageCampaigns().then((data) => {
    store.dispatch(setActivatedMessageCampaigns(data));
  });
};

export const loadActivatedTours = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.getActiveTours().then((data) => {
    store.dispatch(setActivatedTours(data));
  });
};

export const loadNewsfeedTypes = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const widget = selectWidget(store.getState());
  return SDK.getAllNewsfeedConfig(widget?.organizationId!).then((data) => {
    store.dispatch(setNewsfeedTypes(data));
  });
};

export const loadActivatedCheckLists = (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  return SDK.checkLists.getAllCheckLists().then((data) => {
    store.dispatch(setActivatedCheckLists(data));
  });
};
