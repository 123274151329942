import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useCallback, useMemo, useState } from "react";
import { HomePageWidgetContainer } from "views/home-page/sections/home-page-widget-container";

const truncateHTML = (html: string, maxLength: number) => {
  if (html.length <= maxLength) {
    return html;
  }
  return html.slice(0, maxLength) + "...";
};

export const NewsfeedItemCard = ({
  record,
  mode = "CARD",
}: {
  record: iNewsFeedItem;
  mode?: "PAGE" | "CARD";
}) => {
  const [showFullContent, setShowFullContent] = useState(mode === "PAGE");

  const MAX_CONTENT_LENGTH = 200; // Adjust this as per your requirement
  const truncatedContent = truncateHTML(record.body, MAX_CONTENT_LENGTH);

  const navigator = useNavigator();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const onReadMore = useCallback(() => {
    navigator.navigateTo(`/newsfeed/${record.id}`);
  }, [navigator, record.id]);

  const [addedReaction, setAddedReaction] = useState("");

  const {
    doAction: reactToItemCall,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (reaction: string) =>
        SDK.reactToNewsfeedItem(record.id!, { reaction }),
      // successMessage: "Form has been submitted",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    [record?.id]
  );

  const reactToItem = useCallback(
    (reaction: string) => {
      setAddedReaction(reaction);
      reactToItemCall(reaction);
    },
    [reactToItemCall]
  );

  const Content = useMemo(() => {
    return (
      <>
        {record.header && (
          <img
            className="w-full h-48 object-cover rounded-t-lg"
            src={record.header}
            alt="Feature"
          />
        )}

        {/* Tags */}
        <div className="flex space-x-2 mt-4">
          {record.tags.map((tag) => (
            <span
              className="bg-blue-200 text-blue-800 px-3 py-1 rounded-full text-xs font-semibold"
              key={tag}
            >
              {tag}
            </span>
          ))}
        </div>

        {/* Content */}
        <div className="mt-4">
          <h2 className="text-xl font-semibold">{record.title}</h2>
          <div className="flex items-center mt-2">
            <img
              className="w-8 h-8 rounded-full mr-2"
              src="https://via.placeholder.com/50"
              alt="Beth-Ann"
            />
            <span className="text-sm text-gray-600">Shared by Beth-Ann</span>
          </div>

          {/* Display truncated or full content */}
          <div className="mt-4 text-gray-800">
            <div
              dangerouslySetInnerHTML={{
                __html: showFullContent ? record.body : truncatedContent,
              }}
            ></div>

            {/* Show Read More/Read Less button if content is truncated */}
            {mode === "CARD" && record.body.length > MAX_CONTENT_LENGTH && (
              <button
                onClick={onReadMore}
                className="text-blue-500 mt-2 text-sm"
              >
                {showFullContent ? "Read less" : "Read more"}
              </button>
            )}
          </div>
        </div>

        {/* Reaction Section */}
        <div className="mt-6 border-t pt-4 text-center">
          <p className="text-sm text-gray-600">How would you rate this?</p>
          <div className="flex justify-center space-x-4 mt-2">
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {["💜", "😴", "👎", "🎉"].map((item) => (
              <span
                role="img"
                key={item}
                className="text-2xl cursor-pointer hover:bg-purple-100 rounded-lg p-2 transition-all"
                onClick={() => reactToItem(item)}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </>
    );
  }, [
    mode,
    onReadMore,
    reactToItem,
    record.body,
    record.header,
    record.tags,
    record.title,
    showFullContent,
    truncatedContent,
  ]);

  if (mode === "PAGE") {
    return <div className="p-4">{Content}</div>;
  }

  return <HomePageWidgetContainer>{Content}</HomePageWidgetContainer>;
};
