import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectWidget } from "store/modules/session/session.selectors";
import { ArticlesWidget } from "../sections/articles-widget";
import { ConversationsWidget } from "../sections/conversations-widget";
import { FAQsWidget } from "../sections/faqs-widget";
import { HomeHeader } from "../sections/home-header";
import { HomeHeaderCollapsed } from "../sections/home-header-collapsed";
import { ShopifyWidget } from "../sections/shopify-widget-widget";
import { AnnouncementsWidget } from "../sections/annoucements-widget";
import { TicketsWidget } from "../sections/tickets-widget";
import { ToursWidget } from "../sections/tours-widget";
import { NewsfeedWidget } from "../sections/newsfeed-widget";
import { CheckListsWidget } from "../sections/checklists-widget";

export const HomeTab = () => {
  const [showFloatingHeader, setFloatingHeaderVisibility] = useState(false);
  const onPageScroll = (e: React.UIEvent<HTMLElement>) => {
    const headerThreshold = 100;
    if (showFloatingHeader) {
      if ((e.target as any).scrollTop < headerThreshold) {
        setFloatingHeaderVisibility(false);
      }
    } else {
      if ((e.target as any).scrollTop > headerThreshold) {
        setFloatingHeaderVisibility(true);
      }
    }
  };

  const widget = useSelector(selectWidget);

  return (
    <PageScrollContainer
      className="h-full w-full flex-1 flex flex-col pb-4"
      onScroll={onPageScroll}
    >
      <div
        className="w-full absolute z-0 top-0 primary-gradient-bg-3"
        style={{
          height: "50vh",
        }}
      >
        <div
          className="w-full h-full"
          style={{
            backgroundImage:
              widget?.configurations?.appearance?.headerPattern || undefined,
          }}
        ></div>
        <div
          style={{
            background:
              "linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%)",
          }}
          className="w-fill py-20 -mt-10 absolute bottom-0 w-full"
        ></div>
      </div>
      <HomeHeader className="top-0 w-full z-0 mb-2" />
      {showFloatingHeader && (
        <HomeHeaderCollapsed className="absolute z-20 w-full primary-bg modified-header-style-1" />
      )}
      <div
        className="w-full pb-4"
        // style={{ marginTop: "13rem" }}
      >
        <AnnouncementsWidget />
        <ConversationsWidget />
        {/* <TicketsWidget /> */}
        <ToursWidget />

        {widget?.configurations.shopifyPlugin?.isActive && <ShopifyWidget />}

        {widget?.configurations.knowledgeBase?.isActive && <ArticlesWidget />}

        <CheckListsWidget />

        <NewsfeedWidget />

        <FAQsWidget />
      </div>
    </PageScrollContainer>
  );
};
