import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Reducer,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { parseQuery } from "utils/parse-query";
import { AppStateSliceReducers } from "./modules/app-state/app-state.slice";
import { ArticleCollectionsSliceReducer } from "./modules/article-collection/article-collection.slice";
import { ArticlesSliceReducer } from "./modules/articles/articles.slice";
import { ConversationsSliceReducer } from "./modules/conversations/conversations.slice";
import { MessagesSliceReducers } from "./modules/messages/messages.store";
import { SessionStateSliceReducers } from "./modules/session/session.slice";
import { UsersSliceReducer } from "./modules/users/users.slice";
import { storage } from "./storage.browser";
import { temporaryStorage } from "./storage.private-browser";
import { storeMigrations } from "./store.migrations";
import { iStore } from "./store.model";
import { RegisterWatchers } from "./store.watchers";
import { TicketsSliceReducer } from "./modules/tickets/tickets.slice";
import {
  NewsfeedItemsSlice,
  NewsfeedItemsSliceReducer,
} from "./modules/newsfeed-items/newsfeed-items.slice";

// If Local Storage is null, fill it with empty object
// Fix for (autoRehydrate.js:54)
const { widgetId } = (() => {
  // eslint-disable-next-line no-useless-escape
  const parsedUrl = parseQuery(window.location.href.replace(/^[^\?]+\??/, ""));
  const paths = window.location.pathname.split("/");
  // Todo: Check WIdget ID using isValidateWidgetId
  const widgetId: string =
    parsedUrl.widgetId || (window as any).WIDGET_ID || paths[1] || undefined;
  return { widgetId };
})();

export const history = createBrowserHistory({ basename: `/${widgetId}` });

const persistKey = widgetId ? `root:${widgetId}` : "root";
// console.log("persistKey", persistKey);
let localStorageAccessAvailable = false;
(() => {
  try {
    if (!localStorage.getItem(`persist:${persistKey}`)) {
      localStorage.setItem(`persist:${persistKey}`, "{}");
    }
    localStorageAccessAvailable = true;
  } catch (e) {
    console.log("Using Temporary Storage");
    localStorageAccessAvailable = false;
  }
})();

const rootPersistConfig = {
  key: persistKey,
  storage: localStorageAccessAvailable ? storage : temporaryStorage,
  // Note: Does not accept dot notation
  // blacklist: [
  //   "messages",
  //   "conversations",
  //   "contacts",
  //   "connections",
  //   "contactLists",
  //   "fbPosts",
  // ],
  version: 4,
  whitelist: ["appState"],
  // stateReconciler: autoMergeLevel2 as any,
  migrate: createMigrate(storeMigrations as any, { debug: false }),
  transforms: [],
};

const rootReducer: Reducer<iStore> = combineReducers({
  appState: AppStateSliceReducers,
  session: SessionStateSliceReducers,
  users: UsersSliceReducer,
  conversations: ConversationsSliceReducer,
  messages: MessagesSliceReducers,
  articles: ArticlesSliceReducer,
  articleCollections: ArticleCollectionsSliceReducer,
  tickets: TicketsSliceReducer,
  newsfeedItems: NewsfeedItemsSliceReducer,
  router: connectRouter(history),
});

const rootPersistedReducer = persistReducer(rootPersistConfig, rootReducer);

const rootPersistedReducerWithReset = (state, action) => {
  if (action.type === "users/logout") {
    const { router, _persist } = state;
    state = { router, _persist };
  }
  return rootPersistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootPersistedReducerWithReset,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(routerMiddleware(history)),
  devTools: true,
  enhancers: [],
});

export const persistor = persistStore(store);

// (async () => {
//   await persistor.purge();
// })();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

RegisterWatchers(store);
