import {
  iCheckList,
  iCheckListInstance,
} from "@sdk/services/checklists/checklists-models";
import classNames from "classnames";
import { FolderIcon } from "components/common/icons/folder-icon";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { Spinner } from "components/common/spinner/spinner";
import { useNavigator } from "helpers/hooks/use-navigator";
import { sumBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { loadAllNewsfeedItemsByTypeId } from "store/modules/newsfeed-items/newsfeed-items.helpers";
import { selectNewsfeedItemsByType } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import {
  loadActivatedCheckLists,
  loadNewsfeedTypes,
  loadTicketTypes,
} from "store/modules/session/session.helpers";
import {
  selectActivatedCheckLists,
  selectNewsfeedTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { NewsfeedItemCard } from "views/newsfeed-item-page/newsfeed-item-card";

export const CheckListsPage = ({ disableBack }: { disableBack?: boolean }) => {
  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const {
    state: checkLists,
    isLoading: isLoadingCheckLists,
  } = useSimpleLoaderStore(
    selectActivatedCheckLists,
    loadActivatedCheckLists,
    []
  );

  const navigator = useNavigator();

  const store = useStore();

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-gray">
        <SimplePageHeader
          title={"Tasks"}
          disableBack={disableBack}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center text-lg">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {"✔"}
            </div>
          }
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 pt-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            <Spinner isActive={isLoadingCheckLists}>
              <div className="ticket-list-container animate fade-in-up m-6">
                {checkLists &&
                  checkLists.map((item) => (
                    <CheckListSummaryCard key={item.id} item={item} />
                  ))}
              </div>
            </Spinner>
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};

export const CheckListSummaryCard = ({
  item,
  withoutShadow,
}: {
  item: iCheckListInstance;
  withoutShadow?: boolean;
}) => {
  const progress =
    Math.round(
      (item.completedSteps.length / item.checkList.steps.length) * 10000
    ) / 100;

  const timeLeft = sumBy(
    item.checkList.steps.slice(item.completedSteps.length - 1),
    (step) => step.duration
  );

  const nextStep = item.checkList.steps[item.completedSteps.length];

  const navigator = useNavigator();

  return (
    <div
      className={classNames(
        "p-4 rounded-lg  cursor-pointer hover:shadow-outline",
        {
          "bg-white card-shadow": !withoutShadow,
        }
      )}
      onClick={() => {
        navigator.navigateTo(`/checklists/${item.id}`);
      }}
    >
      <h2 className="text-lg font-semibold">{item.checkList.name}</h2>
      <p className="text-gray-500">{item.checkList.description}</p>
      <div className="flex items-center justify-between mt-2">
        <span className="text-sm text-gray-600">{progress}% done</span>
        <span className="text-sm text-gray-600">{timeLeft} mins</span>
      </div>
      <div className="w-full bg-gray-200 h-2 rounded-full mt-2">
        <div
          className="bg-blue-500 h-2 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      {nextStep && (
        <p className="mt-2 text-sm font-semibold text-blue-600">
          Next step: {nextStep.title}
        </p>
      )}
    </div>
  );
};
