import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectNewsfeedItemById } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { loadNewsfeedTypes, loadTicketTypes } from "store/modules/session/session.helpers";
import {
  selectAllTicketTypes,
  selectNewsfeedTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { NewsfeedItemCard } from "./newsfeed-item-card";

export const NewsfeedItemPage = () => {
  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const history = useHistory();
  const newsfeedItemId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  const newsfeedItem = useSelector(selectNewsfeedItemById(newsfeedItemId));
  const widget = useSelector(selectWidget);

  const {
    state: newsfeedTypes,
    isLoading: isLoadingNewsfeedTypes,
  } = useSimpleLoaderStore(
    selectNewsfeedTypes,
    loadNewsfeedTypes,
    [],
    !newsfeedItem
  );

  const newsfeedType = useMemo(() => {
    return (newsfeedTypes || []).find(
      (t) => t.id === newsfeedItem?.newsFeedTypeId
    );
  }, [newsfeedTypes, newsfeedItem]);

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={
            <div className="flex flex-row">
              <div className="title">{newsfeedType?.pluralNoun}</div>
            </div>
          }
          useNavigationPush={true}
        />
        <div className="page-body flex-1 overflow-hidden">
          <PageScrollContainer
            className=" h-full w-full flex-1 bg-white relative p-4 pt-6"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {!newsfeedItem && <div></div>}

            {newsfeedItem && (
              <>
                 {/* eslint-disable-next-line i18next/no-literal-string */}
                <NewsfeedItemCard record={newsfeedItem} mode="PAGE" />
              </>
            )}
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
