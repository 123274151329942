import { ConnectedRouter } from "connected-react-router";
import React, { Suspense, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { history } from "store/store";
import { ConversationListPage } from "views/conversation-list-page/conversation-list.page";
import { ConversationPage } from "views/conversation-page/conversation.page";
import { HomePage } from "views/home-page/home.page";
import { KbHomePage } from "views/knowledge-base-pages/kb-home.page";
import { SearchFAQPage } from "views/search-faqs-page/search-faq.page";
import { ViewArticlePage } from "views/knowledge-base-pages/view-article.page";
import { ViewFAQPage } from "views/view-faq-page/view-faq.page";
import "./app-router.scss";
import { replaceAll } from "./utils/replace-all";
import { KbCollectionPage } from "views/knowledge-base-pages/kb-collection.page";
import { ParentWindowService } from "@parent-window/parent-window";
import { TicketListPage } from "views/tickets-list-page/ticket-list.page";
import { TicketPage } from "views/ticket-page/ticket.page";
import { NewsfeedListPage } from "views/newsfeed-list-page/newsfeed-list-page";
import { NewsfeedItemPage } from "views/newsfeed-item-page/newsfeed-item.page";
import { CheckListsPage } from "views/checklists-page/checklists-page";
import { CheckListPage } from "views/checklist-page/checklist.page";

// Notes
// https://stackoverflow.com/questions/45932263/page-sliding-animation-with-react-router-v4-and-react-transition-group-v2
// Refer answer by Toxxxiczny

const LoadingView = (
  // eslint-disable-next-line i18next/no-literal-string
  <div className="flex items-center justify-center h-screen w-screen">
    Loading...
  </div>
);

const routes = [
  { path: "/", Component: HomePage },
  // Conversations
  { path: "/conversations", Component: ConversationListPage },
  { path: "/conversations/:id1", Component: ConversationPage },
  // FAQs
  { path: "/faq", Component: SearchFAQPage },
  { path: "/faq/:id1", Component: ViewFAQPage },
  // Articles
  { path: "/knowledge-base", Component: KbHomePage },
  { path: "/knowledge-base/collections/:id1", Component: KbCollectionPage },
  { path: "/knowledge-base/articles/:id1", Component: ViewArticlePage },
  // Tickets
  { path: "/tickets", Component: TicketListPage },
  { path: "/tickets/:id1", Component: TicketPage },
  // Newsfeed Items
  { path: "/newsfeed", Component: NewsfeedListPage },
  { path: "/newsfeed/:id1", Component: NewsfeedItemPage },
  // Checklists
  { path: "/checklists", Component: CheckListsPage },
  { path: "/checklists/:id1", Component: CheckListPage },
  // Other
  { path: "*", Component: () => <Redirect to="/" /> },
];

const _AppRootRouter = () => {
  const history = useHistory();
  // const lastPage = {};
  // if (history.location.state) {
  //   lastPage["from"] = history.location.state["from"];
  //   console.log('lastPage["from"]', lastPage["from"])
  // }
  const statePageVisitedArray = history.location.state
    ? history.location.state["pageVisited"]
    : [];
  const newPathDepth = statePageVisitedArray.length;

  const lastPage = statePageVisitedArray.slice(-1)[0];
  const location = useLocation(); // TODO: Its giving an error when I remove this ?????????????

  const [pageDirection, setPageDirection] = useState("left");
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  // const [currentPathOrder, setCurrentPathOrder] = useState(
  //   getPathDepth(location)
  // );
  const [currentPathDepth, setCurrentPathDepth] = useState(
    statePageVisitedArray.length
  );

  const [currentKey, setCurrentKey] = useState(
    replaceAll(history.location.pathname, "/", "-")
  );

  const [currentLocation, setCurrentLocation] = useState(history.location);

  useEffect(() => {
    const newPath = history.location.pathname;
    //const newPathOrder = getPathDepth(location);
    if (newPath !== currentPath) {
      const direction = currentPathDepth < newPathDepth ? "left" : "right";
      setCurrentPath(newPath);
      //setCurrentPathOrder(newPathOrder);
      setCurrentPathDepth(newPathDepth);
      setPageDirection(direction);
      setCurrentKey(replaceAll(history.location.pathname, "/", "-"));
      setCurrentLocation(history.location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (currentPath.includes("/knowledge-base/articles")) {
      ParentWindowService.sendMessage({
        type: "SCREEN_CHANGED",
        data: {
          screen: "ARTICLE_PAGE",
        },
      });
    } else {
      ParentWindowService.sendMessage({
        type: "SCREEN_CHANGED",
        data: {
          screen: "NORMAL_PAGE",
        },
      });
    }
  }, [currentPath]);

  return (
    <Suspense fallback={LoadingView}>
      <TransitionGroup
        className={lastPage !== "/temporary" ? `${pageDirection}` : ``}
      >
        <CSSTransition key={currentKey} timeout={400} classNames={"route"}>
          <div className="route__container">
            <Switch location={currentLocation}>
              {routes.map(({ path, Component }) => (
                <Route key={path} exact path={path} component={Component} />
              ))}
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Suspense>
  );
};

export const AppRootRouter = ({ WIDGET_ID }: { WIDGET_ID: string }) => {
  return (
    <ConnectedRouter history={history}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_AppRootRouter />
    </ConnectedRouter>
  );
};
