import { SDK, useSDK } from "@sdk";
import { Button } from "components/common/button/button";
import { CheckMarkIcon } from "components/common/icons/check-mark";
import { CloseIcon } from "components/common/icons/close-icon";
import { Spinner } from "components/common/spinner/spinner";
import { ToolTipX } from "components/common/tooltip/tooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { loadSession } from "store/modules/session/session.helpers";
import { isEmail } from "utils/is-email";

export const EmailCollector = () => {
  const { t } = useTranslation("common");
  const [email, setEmail] = useState("");
  const isEmailValid = isEmail(email);
  const [isProcessing, setProcessing] = useState(false);
  const [isEmailCollectorVisibility, setEmailCollectorVisibility] = useState(
    false
  );

  const store = useStore();

  // Load Session If it's visible
  useEffect(() => {
    loadSession(store, true).then((d) => {
      setEmailCollectorVisibility(true);
    });
  }, [store]);

  const updateContactDetails = () => {
    setProcessing(true);
    SDK.updateContactDetails({
      // firstName: "",
      // lastName: "",
      primaryEmail: email,
      // primaryMobile: "",
    })
      .then((d) => {
        return loadSession(store, true);
      })
      .catch((d) => {
        //
      });
  };

  if (!isEmailCollectorVisibility) {
    return <></>;
  }

  return (
    <div className="flex flex-col bg-gray-100 p-4 border rounded-lg mt-6">
      <div className="flex flex-row justify-between items-center">
        <div className="font-semibold mt-1 text-sm">
          {t("MISS_OUR_REPLIES_MESSAGE")}
        </div>
        <div
          onClick={() => setEmailCollectorVisibility(false)}
          className="cursor-pointer"
        >
          <CloseIcon />
        </div>
      </div>
      <div className="font-semibold mb-4">{t("GET_NOTIFIED_VIA_EMAIL")}</div>
      <div className="flex flex-row">
        <input
          placeholder={`${t("EMAIL_TEXT")} - you@abc.com`}
          className="border rounded-l-lg p-4 outline-none focus:shadow-lg flex-1"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateContactDetails();
            }
          }}
        />
        {isEmailValid ? (
          <Spinner isActive={isProcessing}>
            <Button
              label={<CheckMarkIcon />}
              style={{ borderRadius: "0 5px 5px 0 !important" }}
              className="rounded-l-none"
              onClick={updateContactDetails}
            />
          </Spinner>
        ) : (
          <ToolTipX content={t("INVALID_EMAIL")}>
            <Button
              label={<CheckMarkIcon />}
              className="cursor-not-allowed rounded-l-none"
              style={{ opacity: 0.2 }}
            />
          </ToolTipX>
        )}
      </div>
    </div>
  );
};
