import { FolderIcon } from "components/common/icons/folder-icon";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { Spinner } from "components/common/spinner/spinner";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { loadAllNewsfeedItemsByTypeId } from "store/modules/newsfeed-items/newsfeed-items.helpers";
import { selectNewsfeedItemsByType } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import {
  loadNewsfeedTypes,
  loadTicketTypes,
} from "store/modules/session/session.helpers";
import {
  selectNewsfeedTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { NewsfeedItemCard } from "views/newsfeed-item-page/newsfeed-item-card";

export const NewsfeedListPage = ({
  disableBack,
}: {
  disableBack?: boolean;
}) => {
  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const {
    state: newsfeedTypes,
    isLoading: isLoadingNewsfeedTypes,
  } = useSimpleLoaderStore(selectNewsfeedTypes, loadNewsfeedTypes, []);

  const selectedNewsfeed = newsfeedTypes?.[0];

  const selectedNewsfeedId = selectedNewsfeed?.id;

  const { state: newsfeedItems, isLoading } = useSimpleLoaderStore(
    selectNewsfeedItemsByType(selectedNewsfeedId!),
    loadAllNewsfeedItemsByTypeId(selectedNewsfeedId!),
    [selectedNewsfeedId],
    !selectedNewsfeedId
  );

  const navigator = useNavigator();

  const store = useStore();

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-gray">
        <SimplePageHeader
          title={selectedNewsfeed?.pluralNoun!}
          disableBack={disableBack}
          icon={
            <div className="dark-bg rounded-lg p-2 flex justify-center items-center text-lg">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {selectedNewsfeed?.emoji || "📢"}
            </div>
          }
        />
        <div className="primary-bg flex-1 overflow-hidden">
          <PageScrollContainer
            className="h-full w-full flex-1 pt-4 bg-white relative"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            <Spinner isActive={isLoading || isLoadingNewsfeedTypes}>
              <div className="ticket-list-container animate fade-in-up">
                {newsfeedItems.map((item) => (
                  <NewsfeedItemCard key={item.id} record={item} />
                ))}
              </div>
            </Spinner>
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
