import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { SearchIcon } from "components/common/icons/search";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";

import {
  selectActivatedCheckLists,
  selectNewsfeedTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { HomePageWidgetContainer } from "./home-page-widget-container";
import { loadActivatedCheckLists } from "store/modules/session/session.helpers";

import { CheckListSummaryCard } from "views/checklists-page/checklists-page";

export const CheckListsWidget = () => {
  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const {
    state: checkLists,
    isLoading: isLoadingCheckLists,
  } = useSimpleLoaderStore(
    selectActivatedCheckLists,
    loadActivatedCheckLists,
    []
  );

  const visibleItems = useMemo(() => {
    return (checkLists || []).slice(0, 3);
  }, [checkLists]);

  const navigator = useNavigator();

  if (!checkLists || checkLists.length === 0) {
    return <></>;
  }

  return (
    <div>
      {visibleItems.map((item) => (
        <HomePageWidgetContainer key={item.id}>
          <CheckListSummaryCard item={item} withoutShadow={true} />
        </HomePageWidgetContainer>
      ))}

      {visibleItems.length !== checkLists.length && (
        <>
          <HomePageWidgetContainer>
            <div
              className="browse-all-button flex flex-row justify-end items-center p-4 cursor-pointer hover-text-primary"
              onClick={() => {
                navigator.navigateTo(`/checklists`);
                // store.dispatch(setLastVisitedTab("KNOWLEDGE_BASE"));
              }}
            >
              {t("BROWSE_ALL")} Tasks
              <ArrowRightIcon size={"1.1rem"} />
            </div>
          </HomePageWidgetContainer>
        </>
      )}
    </div>
  );
};
