import { SessionStateSliceActions } from "./session.slice";

export const {
  setSession,
  patchSession,
  setContact,
  patchContact,
  setWidget,
  patchWidget,
  patchWidgetColor,
  setTicketTypes,
  setActivatedMessageCampaigns,
  setActivatedTours,
  setNewsfeedTypes,
  setActivatedCheckLists
} = SessionStateSliceActions;
