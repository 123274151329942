import { iConversation } from "@sdk/services/models";
import { SleepIcon } from "components/common/icons/sleep-icon";
import { getUserName } from "helpers/get-user-name";
import { useTeamOfflineWindow } from "helpers/use-team-offline";
import find from "lodash/find";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { selectWidget } from "store/modules/session/session.selectors";
import {
  selectAllActiveUsers,
  selectUserById,
} from "store/modules/users/users.selectors";

const GetUserAwayText = (str: string, user: string) => {
  return str.replace("{{user}}", user);
};

export const TeamOfflineCard = ({
  conversation,
}: {
  conversation: iConversation;
}) => {
  const { t } = useTranslation("common");

  const isOfflineWindow = useTeamOfflineWindow();
  // const isOfflineWindow = true;
  const allActiveUsers = useSelector(selectAllActiveUsers);
  const allAvailableUsers = useMemo(() => {
    return allActiveUsers.filter((user) => user.metaData.isAvailable);
  }, [allActiveUsers]);

  const availableChatMembers = useMemo(() => {
    return allAvailableUsers.filter((user) =>
      find(conversation?.members || [], (member) => member.userId === user.id)
    );
  }, [allAvailableUsers, conversation?.members]);

  const store = useStore();

  const activeUserName = useMemo(() => {
    if (conversation?.members?.length > 0) {
      const firstMember = conversation.members[0];
      const user = selectUserById(firstMember.userId)(store.getState());
      const userName = getUserName(user) || "Team Member";
      return userName;
    }
    return "Team Member";
  }, [conversation?.members, store]);

  const widget = useSelector(selectWidget);
  
  const TEXT_USER_IS_AWAY =
    widget?.configurations?.locale?.custom?.["USER_IS_AWAY"] ||
    t("USER_IS_AWAY");

  const TEXT_OUT_OF_OFFICE =
    widget?.configurations?.locale?.custom?.["OUT_OF_OFFICE"] ||
    t("OUT_OF_OFFICE");

  if (conversation?.status !== "OPEN") {
    return <></>;
  }

  if (conversation?.members?.length > 0 && availableChatMembers.length === 0) {
    return (
      <div className="flex flex-col bg-gray-100 p-4 border rounded-lg mt-6">
        <div className="flex flex-row justify-center items-center">
          <div className="icon-cont mr-2">
            <SleepIcon />
          </div>
          <div className="text-sm font-bold">
            {GetUserAwayText(TEXT_USER_IS_AWAY, activeUserName)}
          </div>
        </div>
      </div>
    );
  }

  if (conversation?.members?.length === 0 && isOfflineWindow) {
    return (
      <div className="flex flex-col bg-gray-100 p-4 border rounded-lg mt-8">
        <div className="flex flex-row justify-center items-center">
          <div className="icon-cont mr-2">
            <SleepIcon />
          </div>
          <div className="text-sm font-bold">{TEXT_OUT_OF_OFFICE}</div>
        </div>
      </div>
    );
  }

  return <></>;
};
