import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectNewsfeedItemById } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { loadTicketTypes } from "store/modules/session/session.helpers";
import {
  selectActivatedCheckLists,
  selectAllTicketTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { CSSTransition } from "react-transition-group";
import "./checklists.scss";
import { sumBy } from "lodash";
import classNames from "classnames";

export const CheckListPage = () => {
  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const history = useHistory();
  const checklistInstanceId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  const allCheckLists = useSelector(selectActivatedCheckLists);

  const checklistInstance = useMemo(
    () => allCheckLists?.find((item) => item.id === checklistInstanceId),
    [allCheckLists, checklistInstanceId]
  );

  const widget = useSelector(selectWidget);

  const [openStepIndex, setOpenStepIndex] = useState<number | null>(0);

  const toggleStep = (index: number) => {
    if (openStepIndex === index) {
      setOpenStepIndex(null);
    } else {
      setOpenStepIndex(index);
    }
  };

  const progress =
    Math.round(
      ((checklistInstance?.completedSteps?.length || 0) /
        (checklistInstance?.checkList?.steps?.length || 1)) *
        10000
    ) / 100;

  const timeLeft = sumBy(
    checklistInstance?.checkList.steps.slice(
      checklistInstance.completedSteps.length - 1
    ),
    (step) => step.duration
  );

  const nextStep =
    checklistInstance?.checkList?.steps?.[
      checklistInstance?.completedSteps?.length
    ];

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={
            <div className="flex flex-row">
              <div className="title">{"Task"}</div>
            </div>
          }
          useNavigationPush={true}
        />
        <div className="page-body flex-1 overflow-hidden">
          <PageScrollContainer
            className=" h-full w-full flex-1 bg-white relative p-4"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {!checklistInstance && <div></div>}

            {checklistInstance && (
              <>
                <div className="p-4">
                  <h1 className="text-xl font-bold">
                    {checklistInstance.checkList.name}
                  </h1>
                  <p className="text-gray-500 mt-2">
                    {checklistInstance.checkList.description}
                  </p>
                  <div className="flex items-center mt-4">
                    <img
                      className="w-10 h-10 rounded-full mr-3"
                      src="https://via.placeholder.com/100"
                    />
                    <span className="text-sm text-gray-600">
                      Shared by Ruban
                    </span>
                  </div>

                  <div className="mt-6">
                    <p className="text-sm text-gray-500 mb-4">
                      {checklistInstance?.completedSteps?.length} of{" "}
                      {checklistInstance.checkList.steps.length} done • About{" "}
                      {timeLeft} min left
                    </p>
                    <div className="w-full bg-gray-200 h-2 rounded-full">
                      <div
                        className="primary-bg h-2 rounded-full"
                        style={{ width: "20%" }}
                      ></div>
                    </div>

                    <div className="mt-12 space-y-4">
                      {checklistInstance.checkList.steps.map((step, index) => (
                        <div
                          key={index}
                          className="rounded-lg border border-gray-200"
                        >
                          <div
                            className="flex items-center justify-between hover:bg-gray-200 cursor-pointer p-4"
                            onClick={() => toggleStep(index)}
                          >
                            <div className="flex items-center">
                              <div
                                className={classNames(
                                  `w-8 h-8 flex items-center justify-center rounded-full font-semibold ${
                                    checklistInstance.completedSteps.includes(
                                      step.id!
                                    )
                                      ? "bg-green-600"
                                      : "primary-bg-200"
                                  }`,
                                  {
                                    "text-white": checklistInstance.completedSteps.includes(
                                      step.id!
                                    ),
                                    "text-primary": !checklistInstance.completedSteps.includes(
                                      step.id!
                                    ),
                                  }
                                )}
                              >
                                {index + 1}
                              </div>
                              <h3 className="ml-4 font-semibold text-gray-800">
                                {step.title}
                              </h3>
                            </div>
                            <div
                              className={`transform transition-transform duration-300 ease-in-out ${
                                openStepIndex !== index ? "rotate-180" : ""
                              }`}
                            >
                              {openStepIndex === index ? (
                                <svg
                                  fill="none"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  fill="none"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>

                          <CSSTransition
                            in={openStepIndex === index}
                            timeout={300}
                            classNames="collapse"
                            unmountOnExit
                          >
                            <div className="mt-4 transition-all duration-300 p-4 pt-0">
                              <p
                                className="text-gray-600"
                                dangerouslySetInnerHTML={{
                                  __html: step.content,
                                }}
                              ></p>
                              {step.actionText &&
                                !checklistInstance.completedSteps.includes(
                                  step.id!
                                ) && (
                                  <button className="mt-4 primary-button px-4 py-2 rounded font-semibold">
                                    {step.actionText}
                                  </button>
                                )}
                            </div>
                          </CSSTransition>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {checklistInstance.isFullyCompleted && (
                  <>
                    <div className="p-4 bg-gray-100 min-h-screen flex items-center justify-center">
                      <div className="bg-white p-6 rounded-lg shadow text-center">
                        <h1 className="text-2xl font-bold">
                          Your first project is live! 🎉
                        </h1>
                        <p className="text-gray-500 mt-2">
                          Next, let's take a look at how to report on your
                          project's progress.
                        </p>
                        <button className="mt-6 bg-blue-500 text-white px-4 py-2 rounded">
                          Continue
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};
