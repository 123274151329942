import { iOrganizationHelpDeskTicketType } from "@sdk/services/ticket-types-model";
import { iTicket } from "@sdk/services/tickets-models";
import classNames from "classnames";
import { AvatarGroup } from "components/common/avatar-group/avatar-group";
import { PageScrollContainer } from "components/common/perfect-scrollbar/perfect-scroll-bar";
import { SimplePageHeader } from "components/common/simple-page-header/simple-page-header";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { UserAvatar } from "components/common/user-avatar/user-avatar";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadTicketTypes } from "store/modules/session/session.helpers";
import {
  selectAllTicketTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { selectTicketById } from "store/modules/tickets/tickets.selectors";
import { selectAllActiveUsers } from "store/modules/users/users.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { CustomFieldRenderer } from "./components/custom-field-renderer";
import "./ticket.page.scss";

export const TicketPage = () => {
  const { t, i18n } = useTranslation("common");

  const dispatch = useDispatch();
  const history = useHistory();
  const ticketId = useMemo(() => {
    const pathArray = history.location.pathname.split("/");
    return pathArray[2];
  }, [history.location.pathname]);

  const ticket = useSelector(selectTicketById(ticketId));
  const widget = useSelector(selectWidget);

  const {
    state: ticketTypes,
    isLoading: isLoadingTicketTypes,
  } = useSimpleLoaderStore(selectAllTicketTypes, loadTicketTypes, [], !ticket);

  const ticketType = useMemo(() => {
    return (ticketTypes || []).find((t) => t.id === ticket?.ticketTypeId);
  }, [ticketTypes, ticket]);

  const allActiveUsers = useSelector(selectAllActiveUsers);

  const assignedUsers = useMemo(() => {
    const assignedUserIds = (ticket?.assignedTo || []).map((u) => u.userId);
    return allActiveUsers.filter((item) => assignedUserIds.includes(item.id));
  }, [allActiveUsers, ticket?.assignedTo]);

  return (
    <div className="flex flex-col w-full h-full primary-gradient-bg-2">
      <div className="flex flex-col w-full h-full max-container bg-white">
        <SimplePageHeader
          title={
            <div className="flex flex-row">
              <div className="title">{t("TICKET_STATUS")}</div>
            </div>
          }
          useNavigationPush={true}
        />
        <div className="page-body flex-1 overflow-hidden">
          <PageScrollContainer
            className=" h-full w-full flex-1 bg-white relative p-4 pt-6"
            // style={{ borderRadius: "8px 8px 0 0" }}
          >
            {!ticket && <div></div>}

            {ticket && (
              <>
                <div className="font-semibold text-gray-800 flex flex-row justify-center items-center mb-4 text-2xl">
                  {ticket.label}
                </div>
                {assignedUsers.length === 0 && (
                  <div className="text-center text-gray-600">
                    {t("TICKET_NOT_ASSIGNED")}
                  </div>
                )}
                {assignedUsers.length > 0 && (
                  <div>
                    <div className="text-gray-600 text-center">
                      {t("TICKET_HANDLED_BY")}
                    </div>
                    <div className="flex flex-row justify-center items-center my-4">
                      {assignedUsers.length === 1 && (
                        <div>
                          <UserAvatar
                            size={"6rem"}
                            isBordered
                            userId={assignedUsers[0].id}
                          />
                        </div>
                      )}
                      {assignedUsers.length > 1 && (
                        <AvatarGroup
                          // eslint-disable-next-line i18next/no-literal-string
                          avatarSize={"6rem"}
                          userList={assignedUsers}
                          maxCount={1}
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-row justify-center items-center">
                  <TicketStatusIndicator
                    ticket={ticket}
                    ticketType={ticketType}
                  />
                </div>

                {ticket.body && (
                  <div className="flex flex-col mb-4 p-4 rounded-lg bg-gray-200">
                    <div
                      dangerouslySetInnerHTML={{ __html: ticket.body }}
                    ></div>
                  </div>
                )}

                <div className="flex flex-col px-4">
                  {ticketType?.properties?.map((field) => (
                    <div className="flex flex-col mb-4" key={field.id}>
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      <div className="font-semibold text-gray-800">
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        {field.label}:
                      </div>
                      <CustomFieldRenderer
                        schema={field}
                        value={ticket.data[field.id]}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </PageScrollContainer>
        </div>
      </div>
    </div>
  );
};

const TicketStatusIndicator = ({
  ticket,
  ticketType,
}: {
  ticket: iTicket;
  ticketType?: iOrganizationHelpDeskTicketType;
}) => {
  if (!ticketType) {
    return <></>;
  }
  return (
    <div className="flex flex-col w-full my-8">
      <div className="stages flex flex-row">
        <StatusStageBlock
          key={ticketType.startingStageLabel}
          id={ticketType.startingStageLabel}
          label={ticketType.startingStageLabel}
          meta=""
          isActive={ticketType.startingStageLabel === ticket.ticketStageId}
        />
        {ticketType.stages.map((stage) => (
          <StatusStageBlock
            key={stage.id}
            id={stage.id}
            label={stage.label}
            meta=""
            isActive={stage.id === ticket.ticketStageId || stage.label === ticket.ticketStageId}
          />
        ))}
        <StatusStageBlock
          key={ticketType.successfulExitStageLabel}
          id={ticketType.successfulExitStageLabel}
          label={ticketType.successfulExitStageLabel}
          meta=""
          isActive={
            ticketType.successfulExitStageLabel === ticket.ticketStageId
          }
        />
      </div>
      {ticketType.failureExistStageLabel === ticket.ticketStageId && (
        <div className="failure text-red-600 text-center font-bold">
          {ticketType.failureExistStageLabel}
        </div>
      )}
    </div>
  );
};

const StatusStageBlock = ({
  id,
  label,
  meta,
  isActive,
}: {
  id: string;
  label: string;
  meta: string;
  isActive?: boolean;
}) => {
  return (
    <div key={id} className="flex flex-col mx-2 flex-1 overflow-hidden">
      <div
        className={classNames("indicator  w-full rounded-lg", {
          "primary-bg": isActive,
          "bg-gray-400": !isActive,
        })}
        style={{ height: 10 }}
      ></div>
      <ToolTipX content={label}>
        <div
          className={classNames("label text-center text-xs ", {
            "text-gray-600": !isActive,
            "text-primary": isActive,
          })}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {label}
          {/* {AddEllipsis(label, 13, false)} */}
          {/* {isActive && AddEllipsis(label, 13, false)} */}
        </div>
      </ToolTipX>

      <div className="text-gray-600">{meta}</div>
    </div>
  );
};
