import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  iContactPublicData,
  iSessionPublicData,
  iWidget,
} from "@sdk/services/models";
import { DeepAssign } from "utils/deep-assign";
import { defaultWidget } from "./data/default-widtet";
import { iSessionState } from "./session.model";
import { iOrganizationHelpDeskTicketType } from "@sdk/services/ticket-types-model";
import { iScreenTourLog } from "@sdk/services/screent-tour-models";
import { iActivatedMessageCampaign } from "@sdk/services/activated-message-campaigns-models";
import { iNewsFeedType } from "@sdk/services/newsfeed/newsfeed-models";
import { iCheckList, iCheckListInstance } from "@sdk/services/checklists/checklists-models";

export const sessionSlice_initialState: iSessionState = {
  session: undefined,
  widgetConfigurations: undefined,
  ticketTypes: [],
};

const sessionStateSlice = createSlice({
  name: "session",
  initialState: sessionSlice_initialState,
  reducers: {
    setSession(state, action: PayloadAction<iSessionPublicData>) {
      state.session = action.payload;
    },
    patchSession(state, action: PayloadAction<iSessionPublicData>) {
      if (state.session) {
        state.session = {
          ...state.session,
          ...action.payload,
          metaData: {
            ...state.session.metaData,
            ...action.payload.metaData,
          },
        };
      }
    },
    setContact(state, action: PayloadAction<iContactPublicData>) {
      if (state.session) {
        state.session.contact = action.payload;
      }
    },
    patchContact(state, action: PayloadAction<iContactPublicData>) {
      if (state.session) {
        state.session.contact = { ...state.session.contact, ...action.payload };
      }
    },
    setWidget(state, action: PayloadAction<iWidget>) {
      const widget = action.payload;
      state.widgetConfigurations = widget;
    },
    patchWidget(state, action: PayloadAction<iWidget>) {
      const widget = action.payload;
      DeepAssign(state.widgetConfigurations, {
        ...widget,
        id: state.widgetConfigurations!.id,
        organizationId: state.widgetConfigurations!.organizationId,
        connectionId: state.widgetConfigurations!.connectionId,
      });
      // state.widgetConfigurations = {
      //   ...state.widgetConfigurations,
      //   ...widget,
      //   id: state.widgetConfigurations!.id,
      //   organizationId: state.widgetConfigurations!.organizationId,
      //   connectionId: state.widgetConfigurations!.connectionId,
      // };
    },
    patchWidgetColor(state, action: PayloadAction<string>) {
      if (
        state.widgetConfigurations?.configurations?.appearance?.colorPallet
          .primary
      ) {
        state.widgetConfigurations.configurations.appearance.colorPallet.primary =
          action.payload;
      }
    },
    setTicketTypes(
      state,
      action: PayloadAction<iOrganizationHelpDeskTicketType[]>
    ) {
      state.ticketTypes = action.payload;
    },
    setActivatedTours(state, action: PayloadAction<iScreenTourLog[]>) {
      state.activatedTours = action.payload;
    },
    setActivatedMessageCampaigns(
      state,
      action: PayloadAction<iActivatedMessageCampaign[]>
    ) {
      state.activatedMessageCampaigns = action.payload;
    },
    setNewsfeedTypes(
      state,
      action: PayloadAction<iNewsFeedType[]>
    ) {
      state.newsfeedTypes = action.payload;
    },
    setActivatedCheckLists(
      state,
      action: PayloadAction<iCheckListInstance[]>
    ) {
      state.activatedCheckLists = action.payload;
    },
  },
});

export const SessionStateSliceReducers = sessionStateSlice.reducer;

export const SessionStateSliceActions = sessionStateSlice.actions;
