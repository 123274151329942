import { Button } from "components/common/button/button";
import { ArrowRightIcon } from "components/common/icons/arrow-right";
import { SearchIcon } from "components/common/icons/search";
import { useNavigator } from "helpers/hooks/use-navigator";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useStore } from "react-redux";
import { setLastVisitedTab } from "store/modules/app-state/app-state.actions";
import {
  loadAllArticles,
  loadAllArticlesByPortalId,
} from "store/modules/articles/articles.helpers";
import {
  selectAllArticles,
  selectArticlesByPortal,
} from "store/modules/articles/articles.selectors";
import {
  selectNewsfeedTypes,
  selectWidget,
} from "store/modules/session/session.selectors";
import { useSimpleLoaderStore } from "store/store.hooks";
import { HomePageWidgetContainer } from "./home-page-widget-container";
import { loadNewsfeedTypes } from "store/modules/session/session.helpers";
import { selectNewsfeedItemsByType } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { loadAllNewsfeedItemsByTypeId } from "store/modules/newsfeed-items/newsfeed-items.helpers";
import { iNewsFeedItem } from "@sdk/services/newsfeed/newsfeed-models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { NewsfeedItemCard } from "views/newsfeed-item-page/newsfeed-item-card";

export const NewsfeedWidget = () => {
  const { t, i18n } = useTranslation("common");

  const widget = useSelector(selectWidget);

  const {
    state: newsfeedTypes,
    isLoading: isLoadingNewsfeedTypes,
  } = useSimpleLoaderStore(selectNewsfeedTypes, loadNewsfeedTypes, []);

  const selectedNewsfeed = newsfeedTypes?.[0];

  const selectedNewsfeedId = selectedNewsfeed?.id;

  const { state: newsfeedItems, isLoading } = useSimpleLoaderStore(
    selectNewsfeedItemsByType(selectedNewsfeedId!),
    loadAllNewsfeedItemsByTypeId(selectedNewsfeedId!),
    [selectedNewsfeedId],
    !selectedNewsfeedId
  );

  const navigator = useNavigator();

  const store = useStore();

  const visibleItems = useMemo(() => {
    return newsfeedItems.slice(0, 3);
  }, [newsfeedItems]);

  if (!newsfeedItems || newsfeedItems.length === 0) {
    return <></>;
  }

  return (
    <div>
      {visibleItems.map((item) => (
        <NewsfeedItemCard key={item.id} record={item} />
      ))}

      {visibleItems.length !== newsfeedItems.length && (
        <>
          <HomePageWidgetContainer>
            <div
              className="browse-all-button flex flex-row justify-end items-center p-4 cursor-pointer hover-text-primary"
              onClick={() => {
                navigator.navigateTo(`/newsfeed`);
                // store.dispatch(setLastVisitedTab("KNOWLEDGE_BASE"));
              }}
            >
              {t("BROWSE_ALL")} {selectedNewsfeed?.pluralNoun}{" "}
              <ArrowRightIcon size={"1.1rem"} />
            </div>
          </HomePageWidgetContainer>
        </>
      )}
    </div>
  );
};
